import Head from 'next/head';
import LandingPage from './components/landing-page';

export default function Home() {
  return (
    <>
      <Head>
        <title>TP Trip</title>
        <meta name="description" content="Generated by create next app" />
        <link rel="icon" href="/favicon.ico" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no"
        ></meta>
        <meta
          http-equiv="Content-Security-Policy"
          content="upgrade-insecure-requests"
        />

        <link
          rel="stylesheet"
          href="https://unpkg.com/primeicons/primeicons.css"
        />

        <script
          src="//code.tidio.co/jlxaakfso6gavzllwdedlrbmwol9cuag.js"
          async
        ></script>
      </Head>
      <main>
        <LandingPage />
      </main>
    </>
  );
}
